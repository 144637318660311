/** ------------------------------
    Sections
    ------------------------------ */
.section {
    margin: 5rem 0;
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.section--padded {
    margin: 0;
    padding: 5rem 0;
    }
