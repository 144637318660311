/* body.learndash-cpt-sfwd-courses .ld-course-status{
    display: none !important;
}
 */
body.learndash-cpt-sfwd-quiz .ld-quiz-status {
    display: none !important;
}

body.learndash-cpt-sfwd-quiz .muted{
    display: none !important;
}

body.learndash-cpt-sfwd-courses .learndash-wrapper {
    display: inline-block;
    width: 100%;
}


/*  lessons page */

body.learndash-cpt-sfwd-lessons .muted{
    display: none !important;
}

body.learndash-cpt-sfwd-lessons .ld-lesson-status {
    display: none !important;
}

body.learndash-cpt-sfwd-lessons .page-header__title {
    display: none !important;
}

body.learndash-cpt-sfwd-lessons .page-header__lead {
    display: none !important;
}


body.learndash-cpt-sfwd-lessons .share .social-list {
    display: none;
}

body.learndash-cpt-sfwd-lessons .learndash-wrapper .ld-content-actions {
    border: 0px !important;
}

body.learndash-cpt-sfwd-lessons .learndash-wrapper .ld-content-actions .ld-content-action .ld-course-step-back {
    font-size: 18px !important;
    margin: 0 10% !important;
}


/* course page  */

/*.ld-course-list-content article.learndash-available.thumbnail.sfwd-courses.type-sfwd-courses.status-publish, .learndash-not-available.learndash-incomplete {
    height: 600px!important;
}*/

.ld-course-list-content .ld-course-list-items .ld_course_grid .ld_course_grid_button {
    margin: 5px 0 0 !important;
    position: absolute;
    bottom: 10px;
    width: 95% !important;
}
.ld-course-list-content .ld-course-list-items .ld_course_grid .thumbnail.course {
    min-height: 450px;
    height: 100%;
}
.ld-course-list-content .ld-course-list-items .ld_course_grid .thumbnail.course img {
    /* height: 200px !important; */
    margin: 3rem auto 1rem;
}
.ld-course-list-content .ld-course-list-items .ld_course_grid .thumbnail .caption {
    padding: 9px 9px 50px;
}

.ld-course-list-content .ld-course-list-items .ld_course_grid .entry-content {
    display: none !important;
}

.learndash-cpt-sfwd-courses .page-header {
    margin: 4rem 0 2rem;
}

.learndash-cpt-sfwd-courses .custom_title_style {
    font-size: 3rem;
    color: #ff006d;
    font-weight: 700;
    max-width: inherit;
}

.learndash-cpt-sfwd-courses h1.page-header__title {
    display: none;
}

.learndash-cpt-sfwd-courses .extra.button {
    /*float: right;
    position: absolute;
    right: 19%;
    top: 10%;*/
    text-align: right;
}

.learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-name {
    align-items: center;
}

.learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-list-item-preview .ld-status-icon {
    margin-top: 0 !important;
}
.learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-details .ld-certificate-link {
    display: flex;
}
@media only screen and (min-width: 320px) and (max-width: 1024px)  {
    .learndash-cpt-sfwd-courses .extra.button {
        float: left;
        position: relative;
        width: 100%;
        margin: 0px 0px 20px;
        right: 0% !important;
        top:0% !important;
    }
}

/*
@media only screen and (min-width: 1920px)   {
    .learndash-cpt-sfwd-courses .extra.button {
        float: right;
        position: absolute;
        right: 27% !important;
        top:10% !important;
    }
}
*/

.reset-button {
    position: absolute;
    right: 18px;
    bottom: 2px;
    font-weight: 700;
    font-size: 12px;
    background: #fff;
    height: 26px;
    padding: 0 1em;
    line-height: 26px;
    color: #728188;
    text-decoration: none;
    display: inline-block;
}

.reset-button::before {
    content: 'x';
    margin-right: 0.25em;
    display: inline-block;
}
.learndash-wrapper .ld-item-list .ld-item-search .ld-item-search-fields .ld-item-search-name a.ld-reset-button {
    right: 18px;
    top: 27px;
}

.leardash-extra-buttons.extra.button {
    margin-bottom: 20px;
}
.learndash_post_sfwd-lessons .learndash-wrapper .ld-content-actions {
    justify-content: center;
}
.learndash_post_sfwd-lessons .learndash-wrapper .ld-content-actions .ld-content-action {
    display: none;
}
.learndash_post_sfwd-lessons .learndash-wrapper .ld-content-actions .ld-content-action:nth-child(2) {
    flex-basis: 300px;
    display: block;
}
body.learndash-cpt-sfwd-lessons .learndash-wrapper .ld-content-actions .ld-content-action .ld-course-step-back {
    margin: 0 !important;
}

.learndash_post_sfwd-lessons .lesson-single-sections {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.learndash_post_sfwd-lessons .lesson-single-sections .lesson-left, .learndash_post_sfwd-lessons .lesson-single-sections .lesson-right {
    width: 48%;
}
.learndash_post_sfwd-lessons .lesson-single-sections .lesson-left img {
    width: 100%;
}
.learndash_post_sfwd-lessons .lesson-single-sections .lesson-right {
    padding-left: 20px;
}

.ld-profile .no-course-text {
    font-size: 18px;
}

.learndash-wrapper .ld-login-modal .ld-login-modal-wrapper {
    min-width: 100% !important;
}
.learndash-wrapper .ld-login-modal .ld-login-modal-form .ld-login-modal-branding {
    display: none !important;
}
.ld-course-status-price {
    flex-direction: column;
}
.course-price-vat-label {
    font-size: 10px;
    margin-top: 5px;
}